<template>
    <div>
      <b-overlay :show="show" no-wrap fixed z-index="9999">
        <template v-slot:overlay>
            <div class="d-flex align-items-center">
              <b-spinner small type="grow" variant="dark"></b-spinner>
              <b-spinner type="grow" variant="dark"></b-spinner>
              <b-spinner small type="grow" variant="dark"></b-spinner>
            </div>
          </template>
      </b-overlay>
      <router-link to="/admin/blogs/create" class="btn btn-success">ثبت خبر</router-link>
      <b-card title="اخبار" class="mt-3">
        
        <b-table
          responsive
          striped
          hover
          v-if="items.data"
          :fields="table_fields"
          :items="items.data.data">

          <template v-slot:cell(created_at)="data">
            {{ data.item.created_at | persianDate }}
          </template>
          <template v-slot:cell(category)="data">
            {{ data.item.category.name }}
          </template>
          <template v-slot:cell(status)="data">
            <span class="badge badge-success" v-if="data.item.status == 1">فعال</span>
            <span class="badge badge-light" v-else>غیرفعال</span>
          </template>

          <template v-slot:cell(edit)="data">
              <router-link :to="{ name: 'blogs.edit.index', params: { blog: data.item.id } }" class="btn btn-primary" @click="editItem(data.index)"><i class="fa fa-edit"></i></router-link>
          </template>
          <template v-slot:cell(delete)="data">
              <button class="btn btn-danger" @click="deleteItem(data.item.id)"><i class="fa fa-close"></i></button>
          </template>
        </b-table>
        <div class="alert alert-danger text-right" v-if="items.data && items.data.data == ''">
          <span class="text-right">موردی یافت نشد ! </span>
        </div>
      </b-card>

      <pagination :limit="2" v-if="items.data" :data="items.data" @pagination-change-page="loadItems"></pagination>
      
    </div>
  </template>

<script>
import mixins from '../mixins/mixins'
export default {
  mixins: [mixins],
  data () {
    return {
      url: '/api/admin/blogs',
      title: 'اخبار',
      table_fields: [
        { key: 'id', label: 'شناسه ' },
        { key: 'title', label: 'عنوان' },
        { key: 'category', label: 'دسته بندی' },
        { key: 'created_at', label: 'تاریخ ثبت' },
        { key: 'status', label: 'وضعیت' },
        { key: 'edit', label: 'ویرایش ' },
        { key: 'delete', label: 'حذف' }
      ]
    }
  },
  methods: {

  },
  created () {
    this.loadItems()
  }
}
</script>
